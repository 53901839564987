.landingPage-container{
    height:100vh;
    background:black;
}
.nav-container{
    border-bottom: 1px solid rgba(130, 130, 130, 0.42);
}
.navwidth{
    width:58%;
}
.navwidth a{
    text-decoration:none;
    position:relative;
}
.shark-capital{
    position:relative;
}
.shark-capital:before{
    content:"";
    position:absolute;
    top:2.1rem;
    height:4px;
    width:100%;
    background:linear-gradient(to right, #22B4DA , rgba(196, 196, 196, 0) );
    /* transform: matrix(0, -1, 1, 0, 0, 0); */
}
.shark{
    font-size:1.4rem;
    font-weight: 600;
}
.capital{
    font-size:1.4rem;
    font-weight:300;
}
.navwidth a:before{
    content:"";
    position:absolute;
    top:1.5rem;
    width:100%;
    height:2px;
    background:blue;
    visibility:hidden;
}
.navwidth a:hover a:before{
    visibility:visible;
}
.invest{
    font-weight: 700;
}
.strategy{
    color:#A5A1A1;
}
.line{
    width:3px;
    height:2rem;
    background:#1C8FFA;
}
.powered{
    color: #1C8FFA;
}
@media (max-width:768px){
    .navwidth{
        width:100%;
    }
}